<template>
    <div class="container">
         <Pbanner v-if="recruitData.catinfo" :pbanner="recruitData.catinfo.thumb"/>
         <div class="leval-menu">
            <div class="leval-menu-wrap inner">
            <Levalmenu :menuNumber="menuNumber"/>
            <div class="posites">
                <span>所在位置：</span>
                <router-link to="/home">首页</router-link>
                <b>></b>
                <router-link to="/concept/concept">人才中心</router-link>
                <b>></b>
                <router-link to="/concept/recruit">校园招聘</router-link>
            </div>
            </div>  
        </div>
        <div class="recruits">
            <strong class="recruite-tt">校园招聘</strong>
            <div class="recruit-info">
                <div class="recruit-top">
                    <span>岗位名称</span><span>学历要求</span>
                    <span>招聘人数</span><span>工作地点</span>
                    <span>发布时间</span>
                </div>
                <el-collapse accordion>
                    <el-collapse-item class="recuit-item" v-for="item in recruitData.list" :key="item.id">
                        <template slot="title">
                            <div class="recruit-btn">
                                <span>{{item.title}}</span><span>{{item.degree}}</span>
                                <span>{{item.job_number}}人</span><span>{{item.place}}</span>
                                <span>{{item.create_time}}</span>
                            </div>
                        </template>
                        <div class="recuit-text1" v-html="item.notes"></div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
    </div>
</template>

<script>
import Pbanner from '../../components/Pbanner';
import Levalmenu from '../../components/Levalmenu';
export default {
    data(){
        return {
            menuNumber: 4,
            recruitData: [],
        }
    },
    components: {
        Pbanner,
        Levalmenu,
    },
    methods: {
        async getRecruit(){
            const {data: res} = await this.$http.get("getRecruitNew");
            if(res.status == 1){
                this.recruitData = res.data;
            }else{
                this.$message.error('数据请求失败');
            }
        },
    },
    mounted() {
        this.getRecruit();
    }
}
</script>

<style lang="less">
    .recruits{
        padding: 55px 10.41% 102px;
    }
    .recruite-tt{
        display: block;
        line-height: 1;
        color: #333333;
        font-size: 34px;
    }
    .recruit-info{
        margin-top: 40px;
    }
    .recruit-top{
        display: flex;
        align-items: center;
        margin-bottom: 2px;
        height: 70px;
        background: #c4261d;
        span{
            display: block;
            color: #fff;
            font-size: 18px;
            font-weight: bold;
            &:nth-child(1){
                padding-left: 5.263%;
                width: 19.47%;
            }
            &:nth-child(2){
                width: 19.47%;
            }
            &:nth-child(3){
                width: 19.47%;
            }
            &:nth-child(4){
                width: 19.47%;
            }
        }
    }
    .recuit-item{
        margin-bottom: 2px;
        border-bottom: none;
        &.is-active,&:hover{
            .el-collapse-item__header{
                background: #f1e4e4;
                .el-collapse-item__arrow{
                    color: #c4261d;
                }
            }
            .recruit-btn{
                span{
                    color: #c4261d;
                }
            }
        }
        .el-collapse-item__header{
            position: relative;
            border-bottom: none;
            background: #f5f5f5;
            height: auto;
            line-height: 1;
            transition: 0.5s;
            .el-collapse-item__arrow{
                position: absolute;
                right: 4.86%;
                top: 50%;
                margin-top: -10px;
                font-size: 20px;
                color: #060102;
            }
        }
        .el-collapse-item__wrap{
            border-bottom: none;
            .el-collapse-item__content{
                padding: 0;
            }
        }
    }
    .recruit-btn{
        display: flex;
        align-items: center;
        height: 60px;
        position: relative;
        width: 100%;
         span{
            display: block;
            color: #060102;
            font-size: 16px;
            transition: 0.4s;
            &:nth-child(1){
                padding-left: 5.263%;
                width: 19.47%;
            }
            &:nth-child(2){
                width: 19.47%;
            }
            &:nth-child(3){
                width: 19.47%;
            }
            &:nth-child(4){
                width: 19.47%;
            }
        }
    }
    .recuit-texts{
        padding: 39px 5.263% 30px;
        background: #fafafa;
        color: #333333;
        font-size: 16px;
        line-height: 2;
        strong{
            display: block;
            margin-bottom: 8px;
            margin-top: 55px;
            color: #333333;
            font-size: 18px;
        }
        p:first-child{
            strong{
                margin-top: 0;
            }
        }
    }
</style>