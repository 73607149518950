<template>
    <div class="container">
         <Pbanner v-if="conceptData.catinfo" :pbanner="conceptData.catinfo.thumb"/>
         <div class="leval-menu">
            <div class="leval-menu-wrap inner">
            <Levalmenu :menuNumber="menuNumber"/>
            <div class="posites">
                <span>所在位置：</span>
                <router-link to="/home">首页</router-link>
                <b>></b>
                <router-link to="/concept/concept">人才中心</router-link>
                <b>></b>
                <router-link to="/concept/concept">人才理念</router-link>
            </div>
            </div>  
        </div>
        <div class="concept">
            <div class="concept-top">
                <div class="concept-logo" v-if="conceptData.info">
                    <img :src="conceptLogo" alt=""/>
                    <strong>{{conceptData.info.description}}</strong>
                </div>
                <div class="concept-info" v-html="conceptData.info.content"></div>
            </div>
            <div class="concept-locate" :style="{backgroundImage:`url(${conceptBg})`}">
                <div class="concep-wrap">
                    <p>查看在招职位</p>
                    <strong>欢迎加入安吉物流大家庭</strong>
                    <router-link to="/concept/recruit">查看职位</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Pbanner from '../../components/Pbanner';
import Levalmenu from '../../components/Levalmenu';
export default {
    data(){
        return {
            menuNumber: 4,
            conceptLogo: require('../../assets/15.png'),
            conceptData: [],
            conceptBg: require('../../assets/15.jpg')
        }
    },
    components: {
        Pbanner,
        Levalmenu,
    },
    methods: {
        async getConcept(){
            var qs = require('qs');
            const {data: res} = await this.$http.post("getPageInfo",qs.stringify({catid: 14}));
            console.log(res)
            if(res.status == 1){
                this.conceptData = res.data;
            }else{
                this.$message.error('数据请求失败');
            }
        },
    },
    mounted() {
        this.getConcept();
    }
}
</script>

<style lang="less">
    .concept{
        padding: 76px 10.41% 65px;
    }
    .concept-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .concept-logo{
        display: block;
        width: 36.3%;
        img{
            display: block;
            width: 100%;
            height: auto;
        }
        strong{
            display: block;
            margin-top: 52px;
            line-height: 1.2;
            color: #333333;
            font-size: 36px;
            font-weight: normal;
        }
    }
    .concept-info{
        width: 53.94%;
        color: #333333;
        font-size: 16px;
        p{
            margin-bottom: 28px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .concept-locate{
        padding: 196px 0 218px;
        margin-top: 40px;
        background-position: center;
        background-size: cover;
    }
    .concep-wrap{
        text-align: center;
        p{
            line-height: 1;
            color: #fff;
            font-size: 16px;
        }
        strong{
            display: block;
            margin-top: 24px;
            margin-bottom: 50px;
            line-height: 1.2;
            color: #fff;
            font-size: 38px;
            font-weight: normal;
        }
        a{
            display: block;
            margin: auto;
            width: 172px;
            height: 48px;
            line-height: 48px;
            text-align: center;
            background: #fff;
            color: #c4261d;
            font-size: 16px;
            &:hover{
                background: #c4261d;
                color: #fff;
            }
        }
    }
</style>